import { serviceItemAnchor } from 'experiences/home/FinancialServices/FinancialServices.css'
import { ExternalIcon } from './assets/ExternalIcon'
import { Card, Icon, Image, Text, View } from 'reshaped/bundle'
import Link from 'next/link'

interface Props {
	name: string
	icon: string
	href: string
	external?: boolean
}

export const ServiceItem = ({ name, icon, href, external }: Props) => {
	const linkProps = external && { target: '_blank', rel: 'noopener noreferrer nofollow' }

	return (
		<View.Item columns={{ s: 12, m: 6, l: 4, xl: 3 }} className={serviceItemAnchor}>
			<Link href={href} {...linkProps} style={{ textDecoration: 'none' }}>
				<View shadow="overlay" attributes={{ style: { borderRadius: '8px' } }}>
					<Card padding={0}>
						<View gap={4} paddingBlock={16} align="center">
							<Image src={icon} width={17} height={17} alt={name} />
							<View direction="row" gap={1} align="center">
								<Text align="center" variant="featured-3" weight="bold">
									{name}
								</Text>
								{external && <Icon svg={ExternalIcon} />}
							</View>
						</View>
					</Card>
				</View>
			</Link>
		</View.Item>
	)
}
