class ContentfulError extends Error {
	constructor(message?: string) {
		super(message)
		this.name = 'ContentfulError'
	}
}

export class ObjectNotFoundError extends ContentfulError {
	constructor(detail = 'No Details') {
		super(`Object was not found (${detail})`)
		this.name = 'ObjectNotFoundError'
	}
}

export class UnexpectedContentType extends ContentfulError {
	constructor(detail = 'No Details') {
		super(`Unexpected Content Type  (${detail})`)
		this.name = 'UnexpectedContentType'
	}
}
