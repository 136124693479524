export const ExternalIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5002 3.33329C12.04 3.33329 11.6669 2.9602 11.6669 2.49996C11.6669 2.03972 12.04 1.66663 12.5002 1.66663H17.5002C17.9604 1.66663 18.3335 2.03972 18.3335 2.49996V7.49996C18.3335 7.9602 17.9604 8.33329 17.5002 8.33329C17.04 8.33329 16.6669 7.9602 16.6669 7.49996V4.5118L8.92279 12.2559C8.59736 12.5813 8.06972 12.5813 7.74428 12.2559C7.41884 11.9304 7.41884 11.4028 7.74428 11.0774L15.4884 3.33329H12.5002ZM4.16687 5.83329C3.94586 5.83329 3.73389 5.92109 3.57761 6.07737C3.42133 6.23365 3.33354 6.44561 3.33354 6.66663V15.8333C3.33354 16.0543 3.42133 16.2663 3.57761 16.4225C3.7339 16.5788 3.94586 16.6666 4.16687 16.6666H13.3335C13.5546 16.6666 13.7665 16.5788 13.9228 16.4225C14.0791 16.2663 14.1669 16.0543 14.1669 15.8333V10.8333C14.1669 10.3731 14.54 9.99996 15.0002 9.99996C15.4604 9.99996 15.8335 10.3731 15.8335 10.8333V15.8333C15.8335 16.4963 15.5701 17.1322 15.1013 17.6011C14.6325 18.0699 13.9966 18.3333 13.3335 18.3333H4.16687C3.50383 18.3333 2.86794 18.0699 2.3991 17.6011C1.93026 17.1322 1.66687 16.4963 1.66687 15.8333V6.66663C1.66687 6.00358 1.93026 5.3677 2.3991 4.89886C2.86794 4.43002 3.50383 4.16663 4.16687 4.16663H9.16687C9.62711 4.16663 10.0002 4.53972 10.0002 4.99996C10.0002 5.4602 9.62711 5.83329 9.16687 5.83329H4.16687Z"
				fill="currentColor"
			/>
		</svg>
	)
}
