import { ROUTES } from 'parameters/index'
import { useAuthState } from 'services/Authentication'
import { useHasMounted } from 'lib/renderWrappers/ClientOnly'

const supportsExplorePagesHrefs = [
	ROUTES.PERSONAL_LOANS,
	ROUTES.STUDENT_LOAN_REFINANCE,
	ROUTES.LIFE_INSURANCE,
	ROUTES.CREDIT_CARDS
]

export const resolveEligibleHref = (href: string) => {
	if (supportsExplorePagesHrefs.includes(href)) {
		return href.replace('products', 'explore')
	}

	return href
}

export const useExploreEligible = () => {
	const nativeABEnabled = true
	const { userType } = useAuthState()
	const hasMounted = useHasMounted()
	return hasMounted && (userType === 'loggedIn' || nativeABEnabled)
}
