import { Resource } from 'services/ContentfulService/models/resource/resource'
import { UnexpectedContentType } from 'services/ContentfulService/errors'
import { Block, BLOCKS, Document, Inline } from '@contentful/rich-text-types'
import { ID } from 'lib'

export const ResourceUtils = (resource: Resource) => ({
	getRichContent: (): Document => {
		switch (resource.contentType) {
			case 'resource':
				if (resource.resource.richTextValue) return resource.resource.richTextValue
			case 'resourceGroup':
				break
		}
		throw new UnexpectedContentType()
	},
	getPlainTextContent: (): string => {
		switch (resource.contentType) {
			case 'resource':
				if (resource.resource.textValue) return resource.resource.textValue
			case 'resourceGroup':
				break
		}
		throw new UnexpectedContentType()
	},
	getMediaContent: () => {
		switch (resource.contentType) {
			case 'resource':
				if (resource.resource.mediaValue) return resource.resource.mediaValue
			case 'resourceGroup':
				break
		}
		throw new UnexpectedContentType()
	},
	getResourceGroup: () => {
		switch (resource.contentType) {
			case 'resourceGroup':
				if (resource.resourceGroup.resources) return resource.resourceGroup.resources
			case 'resource':
				break
		}
		throw new UnexpectedContentType()
	}
})
export const idFromNode = (node: Block | Inline) => {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const value = node.content[0].value as string
		return encodeURIComponent(value)
	} catch (e) {
		return ID()
	}
}
interface Anchors {
	text: string
	id: string
}
export const extractHeadings = (document: Document): Anchors[] => {
	return document.content
		.filter(node => [BLOCKS.HEADING_2].includes(node.nodeType))
		.map(node => {
			const content = node.content[0]
			// eslint-disable-next-line sonarjs/no-small-switch
			switch (content.nodeType) {
				case 'text':
					return { text: content.value, id: idFromNode(node) }
			}
			return { text: 'error', id: ID() }
		})
}
