import { Text, View } from 'reshaped/bundle'
import { ROUTES } from 'parameters/index'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { resolveEligibleHref, useExploreEligible } from '../shared'
import { ServiceItem } from 'experiences/home/FinancialServices/ServiceItem'
import { cnfSearchRedirectLink } from 'experiences/explore/loans/components/NewLoanSearchWidget/config'

const items = [
	{ key: 'home.section_one.item_one', href: ROUTES.PERSONAL_LOANS },
	{ key: 'home.section_one.item_four', href: cnfSearchRedirectLink },
	{ key: 'home.section_one.item_nine', href: cnfSearchRedirectLink },
	{ key: 'home.section_one.item_seven', href: ROUTES.MORTGAGE_REFINANCE },
	{ key: 'home.section_one.item_five', href: ROUTES.LIFE_INSURANCE },
	{ key: 'home.section_one.item_eight', href: ROUTES.AUTO_INSURANCE, external: true },
	{ key: 'home.section_one.item_two', href: ROUTES.CREDIT_CARDS },
	{ key: 'home.section_one.item_three', href: ROUTES.SAVINGS }
]

export const FinancialServices = () => {
	const pageResources = useContentfulResourcesMapped()
	const eligible = useExploreEligible()

	return (
		<View paddingBlock={{ s: 5, l: 10 }} gap={10}>
			<Text as="h2" align="center" variant="title-3">
				{ResourceUtils(pageResources['home.section_one.title']).getPlainTextContent()}
			</Text>
			<View direction="row" wrap={true} gap={{ s: 4, m: 8 }}>
				{items.map(item => {
					const href = eligible ? resolveEligibleHref(item.href) : item.href

					return (
						<ServiceItem
							name={ResourceUtils(pageResources[item.key]).getPlainTextContent()}
							icon={ResourceUtils(pageResources[item.key]).getMediaContent().url}
							href={href}
							external={item.external}
							key={item.key}
						/>
					)
				})}
			</View>
		</View>
	)
}
